<template>
	<div v-if="isShow">
		<div class="end-time">
			<div class="end-time__title">
				{{ $t('messages.end_time') }}
			</div>
			<div class="end-time__counter">
				<div class="end-time__item">
					<div class="end-time__value">{{ days }}</div>
					<div class="end-time__descr">дни</div>
				</div>
				<div class="end-time__item">
					<div class="end-time__value">{{ hours }}</div>
					<div class="end-time__descr">часы</div>
				</div>
				<div class="end-time__item">
					<div class="end-time__value">{{ minutes }}</div>
					<div class="end-time__descr">минуты</div>
				</div>
				<div class="end-time__item">
					<div class="end-time__value">{{ seconds }}</div>
					<div class="end-time__descr">секунды</div>
				</div>
			</div>
			<a @click="sendEvent('https://eduson.academy/platform-special', 'request_full_course_buy_now')" class="regular-btn end-time-btn">Купить полный курс</a>
		</div>

		<section v-if="isShowPopup" class="counter-page j-counter-popup">
			<div class="counter-page__start">
				<img  class="counter-page__logo" src="../../../static/images/app-logo/eduson-academy-logo.svg" width="110" height="46" alt="Eduson Academy">
				<h2 class="counter-page__title">Ваш пробный период подошёл к&nbsp;концу</h2>
				<p class="counter-page__text">
					Надеемся, вы успели получить полезные знания и навыки за эти несколько дней.
					Приглашаем вас продолжить обучение на нашей программе. Нажмите на&nbsp;кнопку, чтобы получить полный доступ к&nbsp;курсу.
				</p>
				<div class="mt-30 text-center">
					<a @click="sendEvent('https://eduson.academy/platform-special', 'request_full_course_timer_end')" class="large-btn action-btn">Продолжить обучение</a>
					<div class="small-font-size mt-15">
						При приобретении полного доступа к курсу, <br>пожалуйста, <a :href="logout_path" data-method="delete" rel="nofollow">выйдите</a> из текущей сессии
					</div>
				</div>
			</div>
			<div class="counter-page__main">
				<div v-if="grouped_companies" class="counter-courses">
					<h2 class="counter-courses__title">Мои курсы</h2>

					<div class="counter-courses__holder">
						<div v-if="grouped_companies.paid" class="counter-courses__section j-section-courses">
							<h3 class="counter-courses__toggler" role="button" tabindex="0" @click="toggleShowSection">Оплаченные курсы</h3>
							<div class="counter-courses__content">
								<ul>
									<li v-for="course in grouped_companies.paid" class="counter-courses__item" :class="{ 'is-current': course.is_current }">
										<a :href="course.auto_login_url" class="counter-courses__link">{{ course.name }}</a>
									</li>
								</ul>
							</div>
						</div>

						<div v-if="grouped_companies.demo" class="counter-courses__section j-section-courses">
							<h3 class="counter-courses__toggler" role="button" tabindex="0" @click="toggleShowSection">Демонстрационные версии курсов</h3>
							<div class="counter-courses__content">
								<ul>
									<li v-for="course in grouped_companies.demo" class="counter-courses__item" :class="{ 'is-current': course.is_current }">
										<a :href="course.auto_login_url" class="counter-courses__link">{{ course.name }}</a>
									</li>
								</ul>
							</div>
						</div>

						<div v-if="grouped_companies.free" class="counter-courses__section j-section-courses">
							<h3 class="counter-courses__toggler" role="button" tabindex="0" @click="toggleShowSection">Бесплатные курсы</h3>
							<div class="counter-courses__content">
								<ul>
									<li v-for="course in grouped_companies.free" class="counter-courses__item" :class="{ 'is-current': course.is_current }">
										<a :href="course.auto_login_url" class="counter-courses__link">{{ course.name }}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'Counter',

	props: {
		current_user_created_at: {
			type: String,
			required: true
		},
		counter_days: {
			type: Number,
			default: 7
		},
		start_action_day: {
			type: String,
			default: ''
		},
		current_company: {
			type: String,
			required: true
		},
		logout_path: {
			type: String,
			required: true
		},
		events_url: {
			type: String,
			required: true
		},
		current_user_id: {
			type: Number,
			required: false
		},
		current_company_id: {
			type: Number,
			required: false
		},
		user_prolong_demo_course_before: {
			type: String,
			required: false
		},
		grouped_companies: {
			type: Object,
			required: false
		}
	},

	data() {
		return {
			MILLISECONDS_IN_DAY: 86400000,
			startActionDate: this.start_action_day ? this.getParseDate(this.start_action_day) : this.getParseDate(this.current_user_created_at),
			currentUserCreatedAt: this.getParseDate(this.current_user_created_at),
			endDate: '',
			counterDays: this.counter_days,
			isShow: true,
			isShowPopup: false,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		}
	},

	created() {
		if (+this.currentUserCreatedAt.getTime() < +this.startActionDate.getTime()) {
			this.isShow = false;
			return;
		}

		this.endDate = this.user_prolong_demo_course_before
			? +this.getParseDate(this.user_prolong_demo_course_before).getTime()
			: +this.currentUserCreatedAt.getTime() + (this.counterDays * this.MILLISECONDS_IN_DAY);


		this.updateRemainingTime();

		if (this.getDifTime()) {
			setInterval(this.updateRemainingTime, 1000);
		}
	},

	methods: {
		getDifTime() {
			const currentTime = new Date();
			const difference = this.endDate - +currentTime.getTime();

			if (difference > 0) {
				return difference;
			}

			return false;
		},

		updateRemainingTime() {
			const difference = this.getDifTime();

			if (difference > 0) {
				this.days = Math.floor(difference / (1000 * 60 * 60 * 24));
				this.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				this.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
				this.seconds = Math.floor((difference % (1000 * 60)) / 1000);
			} else {
				document.querySelector('.page-content').classList.add('hide');
				document.querySelector('.main-header').classList.add('hide');
				this.isShowPopup = true;
			}
		},

		getParseDate(stringOfDate) {
			const arr = stringOfDate.split(/[\-\+ :T]/);
			const date = new Date();

			date.setUTCFullYear(arr[0]);
			date.setUTCMonth(arr[1] - 1);
			date.setUTCDate(arr[2]);
			date.setUTCHours(arr[3]);
			date.setUTCMinutes(arr[4]);
			date.setUTCSeconds(arr[5]);

			return date;
		},

		sendEvent(redirect_url, event_name) {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				url: this.events_url,
				method: "POST",
				data: {
					name: event_name,
					description: "Запрос из курса: " + this.current_company,
					user_id: this.current_user_id,
					eventable_type: "Company",
					eventable_id: this.current_company_id,
					redirect_url: redirect_url
				}
			}).then((response) => {
				window.location.href = redirect_url;
			})
		},

		toggleShowSection(e) {
			e.target.closest('.j-section-courses').classList.toggle('is-open');
		}
	}
}
</script>

<style>
.counter-page {
	min-height: calc(100vh - 140px);
	padding-bottom: 80px;
	background-color: var(--white);
}

.counter-page__start {
	padding: 40px 0;
	margin-bottom: 40px;
	text-align: center;
}

.counter-page__logo {
	margin-bottom: 16px;
}

.counter-page__title {
	margin-bottom: 16px;
	font-size: 32px;
	line-height: 1.2;
	text-align: center;
	color: #0842a0;
}

.counter-page__text {
	max-width: 700px;
	margin: 0 auto;
	font-size: 18px;
	line-height: 1.4;
	text-align: center;
}

.counter-courses {
	width: 820px;
	margin: 0 auto;
}

.counter-courses__title {
	margin-bottom: 12px;
	font-size: 17px;
	font-weight: 700;
}

.counter-courses__section {
	&:not(:last-child) {
		border-bottom: 1px solid var(--dark-border-color);
	}

	&:last-child {
		.counter-courses__content {
			border-radius: 0 0 5px 5px;
		}
	}

	&.is-open {
		.counter-courses__toggler {
			&::after {
				transform: rotate(270deg);
			}
		}

		.counter-courses__content {
			display: block;
		}
	}
}

.counter-courses__holder {
	border: 1px solid var(--dark-border-color);
	border-radius: 5px;
	overflow: hidden;
}

.counter-courses__toggler {
	position: relative;
	padding: 16px 64px;
	border-radius: 5px 5px 0 0;
	font-size: 15px;
	font-weight: 700;
	cursor: pointer;

	&:hover {
		background-color: var(--middle-bg);
	}

	&::after {
		content: "";
		position: absolute;
		top: 20px;
		right: 44px;
		display: block;
		width: 10px;
		height: 10px;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-image: var(--icon-arrow);
		transform: rotate(90deg);
		transition: 0.1s;
	}
}

.counter-courses__content {
	display: none;
	padding: 10px 0;
	background-color: hsl(var(--action-h), var(--action-s), 96%);
}

.counter-courses__item {
	&.is-current {
		.counter-courses__link {
			pointer-events: none;

			&::before {
				content: "";
				position: absolute;
				top: 18px;
				left: 40px;
				display: block;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background-color: var(--high);
			}
		}
	}
}

.counter-courses__link {
	position: relative;
	display: block;
	padding: 12px 64px;
	font-size: 15px;
	line-height: 1.3;
	color: var(--main-font);

	&:hover {
		background-color: hsl(var(--action-h), var(--action-s), 92%);
	}
}

.end-time {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	padding: 10px 0;
	background-color: #8352ff;
	color: #fff;
	overflow: hidden;
}

.end-time .page-inner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.end-time__title {
	font-size: 20px;
	line-height: 1.4;
	font-weight: 700;
	color: var(--white);
}

.end-time__counter {
	position: relative;
	display: flex;
	justify-content: center;
	gap: 5px 10px;
	margin-left: 160px;
	margin-right: 150px;
}

.end-time__counter::before {
	content: "";
	position: absolute;
	bottom: -11px;
	left: -120px;
	width: 109px;
	height: 80px;
	background-image: url(../../../static/images/counter/clock_3.png);
	background-position: left top;
	background-size: contain;
	background-repeat: no-repeat;
}

.end-time__counter::after {
	content: "";
	position: absolute;
	bottom: -11px;
	right: -160px;
	width: 177px;
	height: 80px;
	background-image: url(../../../static/images/counter/clock_4.png);
	background-position: left top;
	background-size: contain;
	background-repeat: no-repeat;
}

.end-time__item {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 50px;
}

.end-time__value {
	display: block;
	width: 40px;
	height: 40px;
	padding: 8px 4px;
	border-radius: 5px;
	background-color: var(--white);
	color: var(--main-font);
	font-size: 24px;
	font-weight: 700;
	text-align: center;
}

.end-time__descr {
	margin-top: 4px;
	color: var(--white);
	font-size: 12px;
	font-weight: 400;
	text-align: center;
}

.end-time-btn {
	background-color: hsl(47, 100%, 62%);
	color: var(--main-font);
	border-radius: 16px;
	font-weight: 700;
}

.end-time-btn:hover {
	background-color: hsl(47, 100%, 52%);
	color: var(--main-font);
}
</style>
